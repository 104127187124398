"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = unique;
var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));
var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));
var _arrayContainsObject = _interopRequireDefault(require("./arrayContainsObject"));
var _ParseObject = _interopRequireDefault(require("./ParseObject"));
/**
 * @flow
 */

function unique /*:: <T>*/(arr /*: Array<T>*/) /*: Array<T>*/{
  var uniques = [];
  (0, _forEach.default)(arr).call(arr, function (value) {
    if (value instanceof _ParseObject.default) {
      if (!(0, _arrayContainsObject.default)(uniques, value)) {
        uniques.push(value);
      }
    } else {
      if ((0, _indexOf.default)(uniques).call(uniques, value) < 0) {
        uniques.push(value);
      }
    }
  });
  return uniques;
}