"use strict";

/**
 * @flow
 * @private
 */
/* global localStorage */
var StorageController = {
  async: 0,
  getItem: function (path /*: string*/) /*: ?string*/{
    return localStorage.getItem(path);
  },
  setItem: function (path /*: string*/, value /*: string*/) {
    try {
      localStorage.setItem(path, value);
    } catch (e) {
      // Quota exceeded, possibly due to Safari Private Browsing mode
      console.log(e.message);
    }
  },
  removeItem: function (path /*: string*/) {
    localStorage.removeItem(path);
  },
  getAllKeys: function () {
    var keys = [];
    for (var i = 0; i < localStorage.length; i += 1) {
      keys.push(localStorage.key(i));
    }
    return keys;
  },
  clear: function () {
    localStorage.clear();
  }
};
module.exports = StorageController;