"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = escape;
/*
 * @flow
 */

var encoded = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '/': '&#x2F;',
  "'": '&#x27;',
  '"': '&quot;'
};
function escape(str /*: string*/) /*: string*/{
  return str.replace(/[&<>\/'"]/g, function (char) {
    return encoded[char];
  });
}