"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.default = isRevocableSession;
var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));
/**
 * @flow
 */

function isRevocableSession(token /*: string*/) /*: boolean*/{
  return (0, _indexOf.default)(token).call(token, 'r:') > -1;
}