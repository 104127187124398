"use strict";

/**
 * This is a simple wrapper to unify EventEmitter implementations across platforms.
 */
var EventEmitter;
try {
  EventEmitter = require('events').EventEmitter;
} catch (_) {
  // EventEmitter unavailable
}
module.exports = EventEmitter;